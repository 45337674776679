import moment from "moment";

export function FormatDate(date: Date) {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const hour = `0${date.getHours()}`.slice(-2);
    const minute = `0${date.getMinutes()}`.slice(-2);
    const second = date.getSeconds();

    return `${year}-${month}-${day} ${hour}:${minute}`;

    // return `${date.getFullYear()}-` + `0${date.getMonth() + 1}`.slice(-2) +"-"+ `0${date.getDate()}`.slice(-2) + " " + `0${date.getHours()}`.slice(-2) + ":" + `0${date.getMinutes()}`.slice(-2)




}

export const isValidDate = (txt: any) => moment(txt).isValid()