import { useEffect, useState } from 'react';
import { rankWith, scopeEndsWith } from '@jsonforms/core';
import { materialRenderers } from '@jsonforms/material-renderers';
import { JFWandItem, JFLabel, JFParagraph } from 'components/jsonform';
import { Client } from '@stomp/stompjs';
import { useFetchContractCustomFieldsQuery } from 'redux/api/contractApi';
import { WEB_SOCKET_URL } from '../config';

type Props = {
    contractId: string,
    contractType: string
}
const useAIBoostLeasing = ({ contractId, contractType }: Props) => {

    const [aiReady, setAiReady] = useState<boolean>(false);
    const { data: customField, isLoading, refetch } = useFetchContractCustomFieldsQuery({ contractId: contractId, contractTypeId: contractType }, { skip: !contractId || !contractType })

    const [data, setData] = useState(null);
    const [schema, setSchema] = useState(null);
    const [UISchema, setUISchema] = useState(null);

    useEffect(() => {
        if (aiReady) {
            refetch()
        }
    }, [refetch, aiReady])

    useEffect(() => {
        const client = new Client({
      brokerURL: WEB_SOCKET_URL,
            connectHeaders: {},
            debug: function (str) {
                console.log(str);
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        });

        client.onConnect = () => {
            console.log('Connected to WebSocket server');
            setAiReady(true)
            client.subscribe(`/topic/ai-boost/${contractId}`, (message) => {
                console.log('Received message:', message.body);
                if (message.body === "AI_BOOST_PROCESS_COMPLETE") {
                    // setAiReady(true)
                }
            });
        };

        client.onStompError = (frame) => {
            console.error('Broker reported error: ' + frame.headers);
            console.error('Additional details: ' + frame.body);
        };

        client.activate();

        return () => {
            client.deactivate();
        };
    }, [contractId]);

    useEffect(() => {
        if (customField && customField?.data) {
            setData(customField.data)
        }
        if (customField && customField?.schema) {
            setSchema(customField.schema)
        }
        if (customField && customField?.ui) {
            setUISchema(customField.ui)
        }
    }, [customField])

    const handleMouseOver = (e: any) => {
        console.log(e);
    };
    const renderers = [
        ...materialRenderers,
        {
            tester: rankWith(5, scopeEndsWith("lineText")),
            renderer: JFWandItem,
            onmouseover: handleMouseOver,
        },
        {
            tester: rankWith(3, scopeEndsWith("paragraph")),
            renderer: JFParagraph,
        },
        {
            tester: rankWith(3, scopeEndsWith("name")),
            renderer: JFLabel,
        },
    ];
    return {
        data,
        schema,
        UISchema,
        renderers,
        setData,
        isLoading
    };
};

export default useAIBoostLeasing;
