import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import useLocales from 'hooks/useLocales';
import { ITags } from '../../../../../@types/contractResponse';
import { IContractFormValues } from 'constants/initialValues/contractFormInitialValues';
import FMTextareaInput from 'components/reusable/formik-inputs/FMTextareaInput';
import FMAutoCompleteChipInput from 'components/reusable/formik-inputs/FMAutoCompleteChipInput';

type Props = {
    formik: FormikProps<IContractFormValues>
}

const TagsAndDescription = ({ formik }: Props) => {
    const { setFieldValue, values } = formik
    const { translate } = useLocales(); const [tagsOptions, setTagsOptions] = useState<ITags[]>([])
    const [tagName, setTagName] = useState<string>("")
    const [finished, setFinished] = useState<boolean>(false)

    useEffect(() => {
        if (values.tags.length && !finished) {
            setTagsOptions(values.tags)
            setFinished(true)
        }
    }, [finished, values.tags])

    const handleAddTags = (e: React.ChangeEvent<HTMLInputElement>, situation: any, option: any) => {
        if (option === "createOption") {
            const item: ITags = { name: tagName }
            setFieldValue("tags", [...values.tags, item])
            setTagName("")
        } else {
            setFieldValue("tags", situation)
        }
    }

    const handleTextChange = (e: any) => {
        setTagName(e.target.value)
    }

    return (
        <Grid container spacing={2} item>
            <Grid item md={12}>
                <Typography sx={{ color: '#637381', fontWeight: 600 }}>
                    {translate('contract.tags', `Tags`)}
                </Typography>
            </Grid>

            <Grid item md={12}>
                <FMAutoCompleteChipInput
                    name="tags"
                    options={tagsOptions}
                    value={values.tags}
                    onChange={handleAddTags}
                    handleTextChange={handleTextChange}
                    label={translate('contract.tags', 'Tags')}
                    helperText={translate('contract.tagError', 'Please hit enter button to save the tag')} />
            </Grid>

            <Grid item md={12}>
                <Typography sx={{ color: '#637381', fontWeight: 600 }}>
                    {translate('contract.description', `Description`)}
                </Typography>
            </Grid>

            <Grid item md={12}>
                <FMTextareaInput name="description" label={translate('contract.description', 'Description')} />
            </Grid>
        </Grid>
    );
};

export default TagsAndDescription;
