import { FormControl, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { Field, FieldProps } from 'formik'
import moment from 'moment'

type Props = {
    label: string,
    name: string,
    disabled?: boolean
}

const FMDatePicker = ({ label, name, disabled = false }: Props) => {
    console.log();
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <FormControl fullWidth>
                    <DesktopDatePicker
                        disabled={disabled}
                        label={label}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        dayOfWeekFormatter={(day) => `${day}`}
                        value={field.value ? field.value : null}
                        onChange={(newValue) => {
                            const date = newValue && newValue ? moment(newValue).format('YYYY-MM-DD') : null
                            form.setFieldValue(field.name, date);
                        }}
                        renderInput={(params) => <TextField {...field} {...params} />}
                    />
                </FormControl>
            )
            }
        </Field>
    )
}

export default FMDatePicker