import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'

const initialState = {
    contract: null as Record<string, any> | null,
}

type IInitialState = typeof initialState

const store = createSlice({
    name: "contracts",
    initialState,
    reducers: {
        addContractTemp: (state, action: PayloadAction<Record<string, any> | null>) => {
            state.contract = action.payload
        },
        resetContract: (state) => {
            state.contract = null
        }
    },
    extraReducers: (builder) => { }
})

export const { addContractTemp, resetContract } = store.actions
export const contractStore: Reducer<IInitialState> = store.reducer
