import { TextField } from '@mui/material'
import { Field } from 'formik'

type Props = {
    label: string,
    name: string,
    disabled?: boolean,
    rows?: number
}

const FMTextareaInput = ({ label, name, disabled, rows }: Props) => {
    console.log();

    return (
        <Field
            component={TextField}
            name={name}
            label={label}
            multiline
            fullWidth
            disabled={disabled}
            inputProps={{ maxLength: 200 }}
            rows={rows ? rows : 2}
        />
    )
}

export default FMTextareaInput