import { useFetchContractQuery } from "redux/api/contractApi";
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { IContractContent } from "../@types/contractResponse";
import { IContractTypeData, useFetchContractCategoriesQuery } from "redux/api/contractCategoriesAPI";
import useLocales from "hooks/useLocales";
import { useSnackbar } from "notistack";
import { useFetchPartiesQuery } from "redux/api/partyAPI";
import useAuth from "hooks/useAuth";
import { ISuggestedParty } from "pages/contracts/contract-types";
import { IUserSuggestion, useFetchUserSuggestionsQuery } from "redux/api/userAPI";
import { contractFormInitialValues, IContractFormValues } from "constants/initialValues/contractFormInitialValues";
import { isValidDate } from "utils/format-date";

type IEditContractContext = {
    initialValues?: IContractFormValues
    isLoadingContract: boolean
    isLoadingContractTypes: boolean
    isLoadingPartyList: boolean
    isLoadingUsersList: boolean
    contract?: IContractContent
    contractTypes: IContractTypeData[]
    handleHighlight: (txt: string) => void,
    partiesList: ISuggestedParty[],
    usersList: IUserSuggestion[]
}

const EditContractContext = createContext<IEditContractContext>({
    initialValues: undefined,
    isLoadingContract: false,
    isLoadingContractTypes: false,
    contract: undefined,
    contractTypes: [],
    handleHighlight: () => { },
    isLoadingPartyList: false,
    partiesList: [],
    isLoadingUsersList: false,
    usersList: [],
})

type Props = {
    children: ReactNode
}

const EditContractProvider: React.FC<Props> = ({ children }) => {
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();

    const { id: contractId } = useParams()
    const { user } = useAuth();

    const { data: contractData, isLoading: isLoadingContract, isError } = useFetchContractQuery({ contractId: contractId })
    const { data: contractTypes = [], isLoading: isLoadingContractTypes, isError: isErrorContractCategories } = useFetchContractCategoriesQuery()
    const { data: partiesList = [], isLoading: isLoadingPartyList, isError: isErrorParties } = useFetchPartiesQuery(user?.organization.uuid ? user?.organization.uuid : "", { skip: !user?.organization.uuid })
    const { data: usersList = [], isLoading: isLoadingUsersList, isError: isErrorUsers } = useFetchUserSuggestionsQuery()

    // const { data: options = [], isError: isErrorTags } = useFetchTagsSuggestionsQuery()
    // const { data: organizations = [], isError: isErrorOrganizations } = useFetchOrganizationsQuery()
    // const { data: partyTypes = [], isError: isErrorPartyTypes } = useFetchPartyRoleSuggestionsQuery()
    // const { data: locations = [], isError: isErrorLocations } = useFetchStorageLocationsQuery()

    const contract = contractData as IContractContent

    useMemo(() => {
        if (isError || isErrorContractCategories || isErrorParties || isErrorUsers) {
            enqueueSnackbar(translate('contract.serverError', 'Something went wrong'), { variant: 'error' })
        }
    }, [isError, isErrorContractCategories, isErrorParties, isErrorUsers, enqueueSnackbar, translate])


    const [initialValues, setInitialValues] = useState<IContractFormValues>(contractFormInitialValues)

    useEffect(() => {
        if (contract) {
            const formData = { ...initialValues }
            const { contractDetail, contractStatus, suggestedContractPeriod } = contract
            formData.title = contractDetail?.title
            formData.noticePeriodType = contractDetail?.noticePeriodType
            formData.noticePeriod = contractDetail?.noticePeriod
            formData.terms = contractDetail?.terms
            formData.contractStatus = contractStatus
            formData.newPeriodStartDate = isValidDate(suggestedContractPeriod?.startDate) ? suggestedContractPeriod?.startDate : null
            formData.newPeriodEndDate = isValidDate(suggestedContractPeriod?.endDate) ? suggestedContractPeriod?.endDate : null
            formData.tags = contractDetail?.tags
            formData.description = contractDetail?.description

            //----------contractType--------
            if (contract?.aiSuggestionResponse?.contractTypes[0]?.contractTypeId) {
                formData.contractType = +contract?.aiSuggestionResponse?.contractTypes[0]?.contractTypeId
            } else if (contract.contractType?.id) {
                formData.contractType = contract.contractType?.id
            } else {
                formData.contractType = null
            }
            //----------END contractType--------

            // ---------effectiveDate----------
            if (contract?.contractDetail?.effectiveDate) {
                formData.effectiveDate = contract?.contractDetail?.effectiveDate
            }
            // ---------END effectiveDate----------

            // ---------endDate----------
            if (contract?.contractDetail?.endDate) {
                formData.endDate = contract?.contractDetail?.endDate
            }
            // ---------END endDate----------

            // ---------emailReminderDate----------
            if (contract?.contractDetail?.emailReminderDate) {
                formData.emailReminderDate = contract?.contractDetail?.emailReminderDate
            }
            // ---------END emailReminderDate----------

            setInitialValues(formData)
        }
    }, [setInitialValues, contract])

    const handleHighlight = (txt: string | number) => {
        console.log(txt);
    }

    return (
        <EditContractContext.Provider value={{
            initialValues,
            isLoadingContract,
            isLoadingContractTypes,
            contract,
            contractTypes,
            handleHighlight,
            isLoadingPartyList,
            partiesList,
            isLoadingUsersList,
            usersList
        }}>
            {children}
        </EditContractContext.Provider>
    )
}

const useEditContractProvider = () => useContext(EditContractContext)

export { EditContractProvider, EditContractContext, useEditContractProvider }