import { InputLabel, MenuItem, Select, FormControl, SelectChangeEvent } from '@mui/material';


type Props = {
    label: string;
    name: string;
    value: string;
    options: { label: string | number; value: string | number }[];
    isLoading?: boolean;
    onChange: (event: SelectChangeEvent<any>, child: React.ReactNode) => void
};

const InputControlSelect = ({ label, name, options, value, isLoading, onChange }: Props) => {
    console.log();

    return (
        <FormControl fullWidth>
            <InputLabel id={`${name}`}>{label}</InputLabel>
            <Select
                name={name}
                value={value || ""}
                onChange={onChange}
                labelId={`${name}-label`}
                label={label}
                disabled={isLoading}
            >
                {options.map((e) => (
                    <MenuItem key={e.value} value={e.value}>
                        {e.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

    );
};

export default InputControlSelect;
