import { memo, useState, useEffect, Fragment } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Container, Box, Button, MenuItem, Menu, Divider } from '@mui/material';

// ----------------------------------------------------------------------
// import HomeIcon from '@mui/icons-material/Home';
import HomeIcon from '../../../assets/dash_board.svg';
import ContractList from '../../../assets/users.svg';
import NewContract from '../../../assets/new_contract.svg';
import Search from '../../../assets/search.svg';
import Organizations from '../../../assets/users 1.svg';
import User from '../../../assets/profile.svg';
import OrganizationIcon from '../../../svg_icons/organization.svg';

import useRefresh from 'hooks/useRefresh';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import { useNavigate, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// ----------------------------------------------------------------------
import {
  PATH_CONTRACT,
  PATH_ORGANIZATION,
  PATH_USER,
  PATH_GROUP,
  PATH_AUTH,
  PATH_DASHBOARD,
  PATH_INBOX,
  PATH_FOLDER,
  PATH_CONTRACTS,
} from 'routes/paths';
import useAuth from 'hooks/useAuth';

import useLocales from 'hooks/useLocales';
import { showFilter, hideFilter } from 'redux/slices/searchContract';
import { useSelector } from 'react-redux';

import jwtDecode from 'jwt-decode';

// import { hideFilter } from '../../../redux/slices/searchContract';

const RootStyle = styled('div')(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  // top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  top: theme.customPalette?.megaMenuTopMargin ? theme.customPalette?.megaMenuTopMargin : 1,
  backgroundColor: theme.customPalette?.background?.default
    ? theme.customPalette?.background.default
    : theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  const navigate = useNavigate();
  const privateAxiosInstance = useAxiosPrivate();

  const [open, setOpen] = useState<boolean>(false);
  const [openUsers, setOpenUsers] = useState<boolean>(false);
  const [openAccount, setOpenAccount] = useState<boolean>(false);
  const [openOrganizationList, setOpenOrganizationList] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElAccount, setAnchorElAccount] = useState<null | HTMLElement>(null);
  const [anchorElOrganizationList, setAnchorElOrganizationList] = useState<null | HTMLElement>(
    null
  );

  const { pathname, search } = useLocation();

  const [selectedOrganization, setSelectedOrganization] = useState<any>({});

  const [organizationList, setOrganizationList] = useState<any>([]);

  const handleCloseAccount = () => {
    setOpenAccount(false);
  };

  const handleCloseorganization = () => {
    setOpenOrganizationList(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
    setOpenUsers(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUsers = () => {
    setOpenUsers(false);
  };

  const handleClickAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAccount(event.currentTarget);
    setOpenAccount(true);
  };

  const { logout, user, isAuthenticated } = useAuth();
  const theme = useTheme();
  const isVerifiedTheme = theme?.customPalette?.mode === 'verified';

  const handleClickOrganizationList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElOrganizationList(event.currentTarget);
    setOpenOrganizationList(true);
  };

  let fontColor = theme.customPalette?.fontColor ?? '#000';
  let activeFontColor = theme.customPalette?.activeFontColor ?? '#00A364';
  let textTransform = theme.customPalette?.textTransform ?? 'none';

  const refresh = useRefresh();

  const handleLogout = async () => {
    const token = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');

    let userForwardURI = '/';
    if (token) {
      const tokenData: any = jwtDecode(token);
      const logoutResponse = await privateAxiosInstance.get(
        `/us-api/auth-detail/logout?username=${tokenData['cognito:username']}&token=${refreshToken}`
      );
      userForwardURI = logoutResponse?.data?.userForwardURI;
    }
    window.location.href = userForwardURI;

    await logout();
    navigate(PATH_AUTH.logout, { replace: true });
  };

  type User = {
    displayName: string;
    role: string;
  };

  const [userProfile, setProfileValues] = useState<User>({ displayName: '', role: '' });

  useEffect(() => {
    (async () => {
      try {
        getOrganizationList();
        setProfileValues({
          displayName: user?.userInfo?.firstName + ' ' + user?.userInfo?.lastName,
          role: [user?.role].map((role: any) => role.name).join(',') || '',
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const getOrganizationList = async () => {
    try {
      setSelectedOrganization(user?.organization);
      const { data } = await privateAxiosInstance.get('/us-api/users/permittedOrganizations');
      setOrganizationList(data?.content);
    } catch (error) {
      console.log(error);
    }
  };

  const { translate } = useLocales();

  // @ts-ignore
  const state = useSelector((st) => st.searchContract);

  const searchContract = () => {
    showFilter();
    if (state.open) {
      hideFilter();
      navigate(PATH_CONTRACTS.search);
      // showFilter();
    } else {
      navigate(PATH_CONTRACTS.search);
      // showFilter();
    }
  };

  const handleNavigate = () => {
    if (isAuthenticated) {
      navigate('/dashboard/app');
    }
  };

  const handleOrganizationClick = async (option: any) => {
    try {
      handleCloseorganization();
      setSelectedOrganization(option);

      await privateAxiosInstance.post('/us-api/users/changeOrganization', {
        organizationId: option?.id,
      });

      const tokens = await refresh();
      window.localStorage.setItem('accessToken', tokens.id_token);
      window.localStorage.setItem('refreshToken', tokens.refresh_token);
      navigate('/dashboard/app');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenHelp = () => {
    if (isVerifiedTheme) {
      window.open('https://support.verified.eu/hc/en-us', '_blank');
    } else {
      window.open('https://tagd.ai/support/', '_blank');
    }
  };

  const handleOpenServiceStatus = () => {
    window.open('https://tagd.checkly-dashboards.com', '_blank');
  };

  return (
    <RootStyle>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',

            padding: '0.5rem',
            borderRadius: '0.5rem',
            alignItems: 'center',
          }}
        >
          <Box sx={{ margin: '0 1rem' }}>
            <img
              src={
                theme.customPalette?.logo ??
                'https://tagd.ai/wp-content/uploads/2022/10/Logo-Tagd.png'
              }
              style={{ maxHeight: '30px', cursor: 'pointer' }}
              onClick={handleNavigate}
              alt="logo"
            />
          </Box>
          <Button
            sx={{
              textTransform: textTransform,
              margin: '0 0.5rem',
              fontSize: '',
              color: pathname === '/dashboard/app' ? activeFontColor : fontColor,
              '&:hover': {
                color: activeFontColor ?? '',
              },
            }}
            // startIcon={<HomeIcon  />}
            onClick={() => navigate('/dashboard/app')}
          >
            {!theme.customPalette?.showIcons && (
              <img src={HomeIcon} height={'28px'} alt="home-icon" style={{ margin: '0 7px 0 0' }} />
            )}

            {translate('navbar.dashboard', 'Dashboard')}
          </Button>
          <Button
            sx={{
              textTransform: textTransform,
              margin: '0 0.5rem',
              fontSize: '',
              color: pathname === '/folders' ? activeFontColor : fontColor,
              '&:hover': {
                color: activeFontColor ?? '',
              },
            }}
            onClick={() => navigate(PATH_FOLDER.list)}
          >
            {!theme.customPalette?.showIcons && (
              <img
                src={ContractList}
                height={'28px'}
                alt="Contracts"
                style={{ margin: '0 7px 0 0' }}
              />
            )}
            {translate('navbar.Contracts', 'Contracts')}
          </Button>
          <Button
            sx={{
              textTransform: textTransform,
              margin: '0 0.5rem',
              fontSize: '',
              color: pathname === PATH_CONTRACTS.search ? activeFontColor : fontColor,
              '&:hover': {
                color: activeFontColor ?? '',
              },
            }}
            onClick={searchContract}
          >
            {!theme.customPalette?.showIcons && (
              <img src={Search} height={'28px'} alt="search-icon" style={{ margin: '0 7px 0 0' }} />
            )}
            {translate('navbar.searchContract', 'Search contract')}
          </Button>

          <Button
            sx={{
              textTransform: textTransform,
              margin: '0 0.5rem',
              fontSize: '',
              color: pathname === PATH_CONTRACT.newContract ? activeFontColor : fontColor,
              '&:hover': {
                color: activeFontColor ?? '',
              },
            }}
            onClick={() => navigate(PATH_CONTRACT.newContract)}
          >
            {!theme.customPalette?.showIcons && (
              <img
                src={NewContract}
                height={'28px'}
                alt="new-contract"
                style={{ margin: '0 7px 0 0' }}
              />
            )}
            {translate('navbar.newContract', 'New contract')}
          </Button>

          {userProfile.role === 'SuperAdministrator' && (
            <Fragment>
              <Button
                sx={{
                  textTransform: textTransform,
                  margin: '0 0.5rem',
                  fontSize: '',
                  color: ['/organization/new', '/organization/list'].includes(pathname)
                    ? activeFontColor
                    : fontColor,
                  '&:hover': {
                    color: activeFontColor ?? '',
                  },
                }}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
              >
                {' '}
                {!theme.customPalette?.showIcons && (
                  <img
                    src={OrganizationIcon}
                    height={'28px'}
                    alt="organization-icon"
                    style={{ margin: '0 7px 0 0' }}
                  />
                )}
                {translate('navbar.org', 'Organizations')}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(PATH_ORGANIZATION.newOrg);
                    handleClose();
                  }}
                >
                  {translate('navbar.newOrg', 'New organization')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(PATH_ORGANIZATION.list);
                    handleClose();
                  }}
                >
                  {translate('navbar.orgList', 'Organizations list')}
                </MenuItem>
              </Menu>
            </Fragment>
          )}

          {userProfile.role !== 'User' && (
            <Fragment>
              <Button
                sx={{
                  textTransform: textTransform,
                  margin: '0 0.5rem',
                  fontSize: '',
                  color: ['/user/new', '/user/list', '/group/list'].includes(pathname)
                    ? activeFontColor
                    : fontColor,
                  '&:hover': {
                    color: activeFontColor ?? '',
                  },
                }}
                // startIcon={<AccountBoxIcon />}
                // startIcon={<User />}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClickUser}
              >
                {!theme.customPalette?.showIcons && (
                  <img
                    src={Organizations}
                    height={'28px'}
                    alt="users-icon"
                    style={{ margin: '0 7px 0 0' }}
                  />
                )}
                {translate('navbar.users', 'Users')}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElUser}
                open={openUsers}
                onClose={handleCloseUsers}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    // refresh page
                    // window.location.reload();
                    navigate(PATH_USER.newUser, { state: { forceRefresh: true } });

                    handleCloseUsers();
                  }}
                >
                  {translate('navbar.newUsers', 'New user')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(PATH_USER.list);
                    handleCloseUsers();
                  }}
                >
                  {translate('navbar.userList', 'User list')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(PATH_GROUP.list);
                    handleCloseUsers();
                  }}
                >
                  {translate('navbar.userGroups', 'User groups')}
                </MenuItem>
              </Menu>
            </Fragment>
          )}

          {/* <Button
            sx={{
              textTransform: textTransform,
              margin: '0 0.5rem',
              fontSize: '',
              color: pathname === '/contract/new' ? activeFontColor : fontColor,
              '&:hover': {
                color: activeFontColor ?? '',
              },
            }}
            onClick={() => navigate(PATH_INBOX.list)}
          >
            {translate('navbar.inboxLabel', 'Inbox')}
          </Button> */}

          <Button
            sx={{
              margin: '0 0.5rem',
              fontSize: '',
              textTransform: 'none',
              marginLeft: 'auto',
              color: fontColor,
              '&:hover': {
                color: activeFontColor ?? '',
              },
              '&:disabled': {
                color: fontColor,
              },
            }}
            endIcon={organizationList.length > 1 ? <KeyboardArrowDownIcon /> : ''}
            onClick={handleClickOrganizationList}
            disabled={organizationList.length <= 1}
          >
            {/* <img src={Organizations} height={'28px'} alt="users-icon" /> */}
            {!theme.customPalette?.showIcons && (
              <img
                src={OrganizationIcon}
                height={'28px'}
                alt="organization-icon"
                style={{ margin: '0 7px 0 0' }}
              />
            )}
            {selectedOrganization.name}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorElOrganizationList}
            open={openOrganizationList}
            onClose={handleCloseorganization}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {organizationList.map((option: any, index: any) => (
              <MenuItem
                key={index}
                onClick={() => handleOrganizationClick(option)}
                disabled={option.id === selectedOrganization.id}
                selected={option.id === selectedOrganization.id}
              >
                {option.name}
              </MenuItem>
            ))}
          </Menu>

          <Button
            sx={{
              margin: '0 0.5rem',
              fontSize: '',
              textTransform: 'none',
              // marginLeft: 'auto',
              color: pathname === '/dashboard/user/profile' ? activeFontColor : fontColor,
              '&:hover': {
                color: activeFontColor ?? '',
              },
            }}
            // startIcon={<AccountBoxIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClickAccount}
          >
            {!theme.customPalette?.showIcons && (
              <img src={User} height={'30px'} alt="profile-icon" style={{ margin: '0 7px 0 0' }} />
            )}
            {userProfile.displayName}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorElAccount}
            open={openAccount}
            onClose={handleCloseAccount}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(PATH_DASHBOARD.user.profile);
                handleCloseAccount();
              }}
            >
              {translate('navbar.profile', 'Profile')}
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleOpenHelp}>
              {translate('navbar.helpCenter', 'Help center')}
            </MenuItem>

            <MenuItem>
              <a
                href={'https://forms.gle/Y8SL1QhiXwxXRn5R6'}
                style={{ color: '#000000', textDecoration: 'none' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate('menuOption.support', 'Support')}
              </a>
            </MenuItem>

            <MenuItem onClick={handleOpenServiceStatus}>
              {translate('navbar.serviceStatus', 'Service status')}
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleLogout}>{translate('navbar.logout', 'Logout')}</MenuItem>
          </Menu>
        </Box>
      </Container>
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
