import { IContractFormValues, IExtendTerminateOptions } from 'constants/initialValues/contractFormInitialValues';
import { FormikProps } from 'formik';
import moment, { unitOfTime } from 'moment';
import React, { useEffect, useState } from 'react'

type Props = {
    formik: FormikProps<IContractFormValues>
}

function useContractDuration({ formik }: Props) {
    const { setFieldValue, values } = formik
    const [selectedAction, setSelectedAction] = useState<IExtendTerminateOptions>();
    const [terminated, setTerminated] = useState<boolean>(false)


    useEffect(() => {
        if (values.contractStatus === "TERMINATED") {
            setTerminated(true)
        } else {
            setTerminated(false)
        }
    }, [values.contractStatus])

    /** 
     * Manage emailReminderDate field value 
     * @field emailReminderDate
     */
    useEffect(() => {
        if (values.endDate && values.noticePeriod && values.noticePeriodType) {
            let date = '';
            const notificationToDecision = values.notificationToDecesion || 45;
            const noticePeriodType = values.noticePeriodType.toLowerCase() as unitOfTime.DurationConstructor;
            let endDate = values.endDate

            if (values.newPeriodEndDate && selectedAction === IExtendTerminateOptions.EXTEND_CONTRACT) {
                endDate = values.newPeriodEndDate
            }

            if (values.contractStatus === 'DRAFT') {
                date = moment(endDate).subtract(values.noticePeriod, noticePeriodType).subtract(+notificationToDecision, 'days').format('YYYY-MM-DD');
            } else {
                date = moment(endDate).subtract(values.noticePeriod, noticePeriodType).subtract(+notificationToDecision, 'days').format('YYYY-MM-DD');
            }

            setFieldValue('emailReminderDate', date);
        }

        if (!values.noticePeriod || !values.noticePeriodType) {
            setFieldValue('emailReminderDate', '');
        }
    }, [values.endDate, values.noticePeriod, values.noticePeriodType, values.newPeriodEndDate, selectedAction, values.notificationToDecesion, values.contractStatus, setFieldValue]);


    /** 
    * Manage endDate field value 
    * @field endDate
    */
    useEffect(() => {
        if (selectedAction === IExtendTerminateOptions.EXTEND_CONTRACT && values.newPeriodEndDate) {
            setFieldValue("endDate", values.newPeriodEndDate)
        }
    }, [selectedAction, setFieldValue, values.newPeriodEndDate])

    return {
        selectedAction,
        terminated,
        setSelectedAction
    }
}

export default useContractDuration