import React, { createContext, useState, ReactNode, FC, useContext } from 'react';

// @ts-ignore
export const ContractContext = createContext();

type Props = { children: ReactNode };

export function useContract() {
  return useContext(ContractContext);
}

type AreaItem = {
  pageIndex: number;
  height: number;
  width: number;
  left: number;
  top: number;
};

export const ContractProvider: FC<Props> = ({ children }) => {
  const [urlLink, setUrlLink] = useState<string>('');
  const [areas, setAreas] = useState<AreaItem[]>([]);
  const [highlight, setHighlight] = useState<string>(" ");

  const handleHighlight = (text: string | null) => {
    if (text) {
      setHighlight(text);
    }
  }

  return (
    <ContractContext.Provider value={{ urlLink, setUrlLink, areas, setAreas, highlight, handleHighlight }}>{children}</ContractContext.Provider>
  );
};
