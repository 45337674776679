import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { AxiosResponse } from "axios";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

export type IGetContentPermissionParams = { id: number, type: "inbox" | "folder" }

export const contentPermissionAPI = createApi({
    reducerPath: "API/CONTENT_PERMISSIONS",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['contentPermission'],
    endpoints(builder) {
        return {
            getContentPermissions: builder.query<any, IGetContentPermissionParams>({
                query({ id, type }) {
                    let endpointURL = `cs-api/contentPermission/${id}?contentType=${type}`;
                    return {
                        url: endpointURL
                    }
                },
                transformResponse: (res: AxiosResponse) => res,
                providesTags: ["contentPermission"]
            }),

        }
    }
})

export const { useGetContentPermissionsQuery, useLazyGetContentPermissionsQuery } = contentPermissionAPI