import React from 'react'
import { Card, CardContent, styled, Tab, Tabs, Grid, colors } from '@mui/material'
import { Formik, Form, FormikProps } from 'formik';
import useLocales from 'hooks/useLocales'
import General from './Tabs/General/General'
import ContractAiBoostLeasing from 'pages/contracts/contract-form/ai-boost/contract-ai-boost-leasing';
import { useEditContractProvider } from 'contexts/EditContractContext';
import { IContractFormValues } from 'constants/initialValues/contractFormInitialValues';


type Props = {}

const ContractEditForm = ({ }: Props) => {
    const { translate } = useLocales()

    const { initialValues } = useEditContractProvider()
    const [currentTab, setCurrentTab] = React.useState("general");

    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const handleSubmit = (data: any) => {
        console.log(data, initialValues);

    }

    return (
        <RootCard >
            <CardContent>
                {/* Tab headers */}
                <RootTabs onChange={handleChange} value={currentTab} sx={{ backgroundColor: colors.grey[100], padding: '0 .5rem' }}>
                    <Tab label={translate('tabs.general', 'General')} value="general" />
                    {initialValues?.contractType && <Tab label={translate('tabs.aiBoost', 'AI Boost')} value="aiBoost" />}
                </RootTabs>

                {/* Tab contents */}
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    //   validationSchema={validationSchema}
                    enableReinitialize
                >
                    {(formik: FormikProps<IContractFormValues>) => {
                        // const { isSubmitting, values, setFieldValue, touched } = formik;
                        // { console.log("valuesvalues", values) }
                        console.log();

                        return <Form>
                            {currentTab === "general" && <Grid container item spacing={2}>
                                <General formik={formik} />
                            </Grid>}
                            {currentTab === "aiBoost" && initialValues?.contractType && <Grid container item spacing={2}>
                                <ContractAiBoostLeasing contractType={initialValues?.contractType.toString()} />
                            </Grid>}
                        </Form>
                    }}
                </Formik>
            </CardContent>
        </RootCard>
    )
}

export default ContractEditForm

// ==================STYLES=================
const RootTabs = styled(Tabs)(() => ({
    px: 2,
    bgcolor: 'background.neutral',
    mb: 3
}))

const RootCard = styled(Card)(() => ({
    border: '1px solid #b2b2b2',
    borderRadius: 0
}));