import { TFunction } from "i18next";
import { IContractStatus, INoticePeriodTypes, ITags, ITermTypes } from "../../@types/contractResponse"

export interface IContractFormValues {
    title: string,
    effectiveDate: string | null,
    endDate: string | null,
    emailReminderDate: string | null,
    newPeriodStartDate: string | null,
    newPeriodEndDate: string | null,
    contractType: number | null,
    noticePeriod: string | null,
    noticePeriodType: INoticePeriodTypes | null,
    terms: ITermTypes | null
    notificationToDecesion: string | null
    contractStatus: IContractStatus | null
    tags: ITags[],
    description: string | null

}

export const contractFormInitialValues = {
    title: "",
    contractType: null,
    effectiveDate: null,
    endDate: null,
    emailReminderDate: null,
    newPeriodStartDate: null,
    newPeriodEndDate: null,
    noticePeriod: null,
    noticePeriodType: null,
    terms: null,
    notificationToDecesion: null,
    contractStatus: null,
    tags: [],
    description: ""
}

export const getTermsList = (translate: TFunction<"translation", undefined, "translation">) => ([
    { value: 'UNTIL_FURTHER_NOTICE', label: translate('contract.terms.untilFutherNotice', 'Until futher notice') },
    { value: 'AUTO_RENEWAL', label: translate('contract.terms.autoRenewal', 'Auto renewal') },
    { value: 'FIXED', label: translate('contract.terms.fixed', 'Fixed') },
    { value: 'OTHER', label: translate('contract.terms.other', 'Other') },
])

export const getPeriodTypes = (translate: TFunction<"translation", undefined, "translation">) => ([
    { value: '', label: translate('common.none', 'None') },
    { value: 'DAY', label: translate('common.days', 'Days') },
    { value: 'WEEK', label: translate('common.weeks', 'Weeks') },
    { value: 'MONTH', label: translate('common.months', 'Months') },
    { value: 'YEAR', label: translate('common.years', 'Years') },
])

export enum IExtendTerminateOptions {
    EXTEND_CONTRACT = "extendContract",
    TERMINATE_CONTRACT = "terminateContract"
}

export const getExtendTerminateOptions = (translate: TFunction<"translation", undefined, "translation">) => ([
    {
        value: IExtendTerminateOptions.EXTEND_CONTRACT,
        label: translate('contractOptions.extendContract', 'Extend contract'),
    },
    {
        value: IExtendTerminateOptions.TERMINATE_CONTRACT,
        label: translate('contractOptions.terminateContract', 'Terminate contract'),
    },
])
