import { Autocomplete, TextField } from '@mui/material'
import { Field } from 'formik'

type Tag = { name: string, id?: any }

type Props = {
    options: Tag[]
    onChange: any
    handleTextChange: any
    helperText: any,
    value?: Tag[]
    name: string,
    label?: string
}

function FMAutoCompleteChipInput({ options, onChange, handleTextChange, helperText, value, name, label }: Props) {
    return (
        <Field
            multiple
            freeSolo
            options={options}
            getOptionLabel={(option: any) => option.name}
            component={Autocomplete}
            name={name}
            value={value}
            onChange={onChange}
            label="abc"
            renderInput={(params: any) => (
                <TextField
                    label={label}
                    onChange={handleTextChange}
                    {...params}
                    helperText={helperText}
                />
            )}
        />
    )
}

export default FMAutoCompleteChipInput