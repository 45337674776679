import Page from 'components/Page'
import { Container } from '@mui/material'
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs'
import useLocales from 'hooks/useLocales'
import PDFViewer from 'components/contract/PDFViewer'
import { Grid } from '@mui/material'
import ContractEditForm from 'components/contract/ContractEditForm'
import { EditContractProvider } from 'contexts/EditContractContext'

type Props = {}

function ContractEdit({ }: Props) {
    const { translate } = useLocales()

    return (
        <EditContractProvider>
            <Page title={translate('contract.new', 'New contract')}>
                <Container maxWidth={false}>
                    <HeaderBreadcrumbs
                        heading={translate('contract.edit', 'Edit contract')}
                        links={[]}
                    />
                    <Grid container spacing={2} >
                        <Grid item md={6}>
                            <ContractEditForm />
                        </Grid>
                        <Grid item md={6} >
                            <PDFViewer />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </EditContractProvider>
    )
}

export default ContractEdit