import React, { useEffect } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
import { highlightPlugin, Trigger, RenderHighlightTargetProps } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { useParams } from 'react-router';


import { searchPluginData } from '../../lib/PDFSerch';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { matchAndReturn } from 'utils';
import { useContract } from 'pages/contracts/contract-form/contract-provider';
import { useFetchContractQuery } from 'redux/api/contractApi';
import { IContractContent } from '../../@types/contractResponse';

type Props = {};

const PDFViewer = ({ }: Props) => {
  const { id: contractId } = useParams()
  const { data: contractData, isLoading } = useFetchContractQuery({ contractId: contractId })
  const contract = contractData as IContractContent

  const contractContext: any = useContract();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({ sidebarTabs: (defaultTabs) => [] });
  const searchPluginInstance = searchPlugin();
  const searchPluginInstanceData = searchPluginData();
  const { isCollapse, onToggleCollapse } = useCollapseDrawer();

  useEffect(() => {
    if (!isCollapse) {
      onToggleCollapse();
    }
  }, [isCollapse, onToggleCollapse]);


  const pageNavigationPluginInstance = pageNavigationPlugin();

  const highlightPluginInstance = highlightPlugin({
    // @ts-ignore
    // renderHighlights,
    trigger: Trigger.None,
  });

  useEffect(() => {
    if (contractContext?.highlight) {

      const hText = contractContext.highlight
      searchPluginInstanceData.fetchTextContent().then(data => {
        const selectedText: string[] = []
        console.log("fetchTextContent", data);
        data.map((e: string) => {
          const isMatch = matchAndReturn(e, hText)
          if (isMatch) {
            selectedText.push(isMatch)
          }
        })
        console.log("fetchTextContent selectedText", selectedText);
        if (selectedText.length) {
          searchPluginInstance.highlight(selectedText[0]);
        }
      })
    }
  }, [contractContext?.highlight, searchPluginInstance, searchPluginInstanceData]);


  let url = `/placeholder.pdf`
  const mainFile = contract?.files?.find(e => e.type === "MAIN")
  if (mainFile) {
    url = mainFile?.preSignedUrl
  } else if (contract?.files[0]?.preSignedUrl) {
    url = contract?.files[0]?.preSignedUrl
  }

  return (

    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.js">
      <div
        style={{
          // height: '85vh',
          height: '93vh',
          // width: '900px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Viewer
          fileUrl={url}
          plugins={[
            defaultLayoutPluginInstance,
            searchPluginInstance,
            searchPluginInstanceData,
            highlightPluginInstance,
            pageNavigationPluginInstance,
          ]}
        />
      </div>
    </Worker>
  );
};

export default PDFViewer;
