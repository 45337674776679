import { TextField } from '@mui/material';
import { Field } from 'formik';

type Props = {
    label: string;
    name: string;
};

const FMTextInput = ({ label, name }: Props) => {
    console.log();
    return (
        <Field name={name}>
            {({ field, form, meta }: any) => (
                <TextField
                    name={field.name}
                    value={field.value || ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={label}
                    fullWidth
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                />
            )}
        </Field>
    );
};

export default FMTextInput;
