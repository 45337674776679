import * as React from 'react';
import { IContractFolderItem } from '../../../@types/contract';
import { Breadcrumbs, Link, styled, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import HomeIcon from '@mui/icons-material/Home';
import { SharedContext } from 'contexts/SharedContext';
import FolderOpen from '../../../assets/folder_open.svg';

type Props = {
  handleBackToFolder: (e: React.UIEvent<Element, UIEvent>) => Promise<void>;
  goIn: boolean;
  selectFolder: IContractFolderItem | null | undefined;
  getFolders: (id: string) => void;
  maxItems?: number;
  size?: 'lg' | 'sm';
  isLoading?: boolean;
};

const FolderBreadcrumb: React.FC<Props> = ({
  handleBackToFolder,
  goIn,
  selectFolder,
  getFolders,
  maxItems,
  size,
  isLoading,
}) => {
  const { inbox } = React.useContext(SharedContext);
  const fontSize = size === 'lg' ? '1.3rem' : 'inherit';
  return (
    <StyledBreadcrumbs
      maxItems={maxItems}
      separator={<Iconify icon={'ic:round-navigate-next'} />}
      aria-label="breadcrumb"
      sx={{ mt: 2 }}
    >
      <StyledTypography
        sx={{ fontSize: fontSize, display: 'flex', alignItems: 'center' }}
        color="inherit"
        onClick={handleBackToFolder}
      >
        <img src={FolderOpen} height={'20px'} alt="search-icon" style={{ margin: '0 7px 2px 0' }} />
        {inbox?.name}
      </StyledTypography>
      {selectFolder?.folderPath?.map((e) => (
        <StyledTypography
          sx={{ fontSize: fontSize }}
          color={'text.primary'}
          onClick={() => getFolders(e?.uuid)}
          key={e.id}
        >
          {e.name}
        </StyledTypography>
      ))}
    </StyledBreadcrumbs>
  );
};

export default FolderBreadcrumb;

const StyledTypography = styled(Typography)({
  '.MuiButtonBase-root': {
    opacity: '0',
  },
});

const StyledBreadcrumbs = styled(Breadcrumbs)({
  '& .MuiBreadcrumbs-li': {
    display: 'flex',
    transition: 'all .1s linear',
    '&:hover': {
      backgroundColor: '#919eab14',
      borderRadius: '3px',
      cursor: 'pointer',
    },
    '& p': {
      transition: 'all .1s linear',
      color: '#999',
      '&:hover': {
        color: '#333',
      },
    },
  },
  // '& .MuiBreadcrumbs-li a': {
  //     display: "flex",
  //     alignContent: "center",
  //     alignItems: "center",
  //     color: "#999",
  //     textDecoration: "none",
  //     transition: 'all .1s linear',
  //     '&:hover': {
  //         color: "#333",
  //     }
  // },
  '& .MuiBreadcrumbs-li:last-child': {
    cursor: 'default',
    '& p': {
      color: '#333',
    },
  },
  '.MuiBreadcrumbs-separator': {
    marginLeft: '8px',
    marginRight: '8px',
  },
});
