import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

export type IUserSuggestion = { email: string, id: string, name: string, uuid: string }

export const userApi = createApi({
    reducerPath: "API/USER",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['userSuggestions'],
    endpoints(builder) {
        return {
            fetchUserSuggestions: builder.query<IUserSuggestion[], void>({
                query() {

                    let endpointURL = `/us-api/users/suggestions`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["userSuggestions"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

        }
    }
})

export const { useFetchUserSuggestionsQuery } = userApi