import React, { useState, useContext, useMemo } from 'react';
import { Button, Card, CardContent, TextField, Drawer } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from 'hooks/useLocales';
import { ICreateFolder } from '../../../@types/inbox';
import { IContractFolderItem } from '../../../@types/contract';
import { SharedContext } from '../../../contexts/SharedContext';
import { useCreateFolderMutation } from 'redux/api/folderAPI';
import { useSnackbar } from 'notistack';
interface Props {
  handleClose: () => void;
  selectFolder?: IContractFolderItem;
  getFolders: (id?: string) => Promise<void>;
  setNewFolderCreated?: (value: boolean) => void;
}

const CreateNewFolder: React.FC<Props> = ({ handleClose, selectFolder, getFolders, setNewFolderCreated }) => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { inbox } = useContext(SharedContext);

  const [createFolder, { isError, isSuccess }] = useCreateFolderMutation();

  useMemo(() => isError && enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), { variant: 'error' }), [enqueueSnackbar, isError, translate]);
  useMemo(() => isSuccess && enqueueSnackbar(translate('contract.folderCreateSuccessfully', 'Folder created successfully!'), { variant: 'success' }), [enqueueSnackbar, isSuccess, translate]);

  const [folderName, setFolderName] = useState<string>('');
  const [loadingFolderCreate, setLoadingFolderCreate] = useState<boolean>(false);

  const handleCreateFolder = async () => {
    try {
      setLoadingFolderCreate(true);
      if (!inbox) {
        throw Error('Missing inbox data');
      }

      const payload: ICreateFolder = {
        name: folderName,
        inbox: {
          uuid: inbox.uuid,
        },
      };
      if (selectFolder?.uuid) {
        payload.parentFolder = {
          uuid: selectFolder.uuid,
        };
      }

      const data = await createFolder({ payload });
      if (setNewFolderCreated) {
        if (data) {
          setNewFolderCreated(true);
        }
      }

      await getFolders(selectFolder?.uuid);
      handleClose();

      setFolderName('');
    } catch (error) {
    } finally {
      setLoadingFolderCreate(false);
    }
  };

  return (
    <Drawer
      PaperProps={{
        style: {
          position: 'absolute',
        },
      }}
      variant="permanent"
      anchor="bottom"
    >
      <Card sx={{ width: '100%' }}>
        <CardContent sx={{ display: 'flex' }}>
          <TextField
            onChange={(e) => setFolderName(e.target.value)}
            required={true}
            sx={{ flex: 1, textTransform: 'none' }}
            id="outlined-basic"
            size="small"
            label={translate('contract.folderName', 'Folder name')}
            variant="outlined"
          />
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ ml: 1, mr: 1 }}
            onClick={handleCreateFolder}
            disabled={folderName ? false : true}
            loading={loadingFolderCreate}
          >
            {translate('contract.permission.save', 'Create')}
          </LoadingButton>

          <Button onClick={handleClose} variant="outlined">
            {translate('contract.cancel', 'Cancel')}
          </Button>
        </CardContent>
      </Card>
    </Drawer>
  );
};

export default CreateNewFolder;
